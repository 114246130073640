import dashboard from './dashboard';
// import application from './application';
// import forms from './forms';
// import elements from './elements';
// import pages from './pages';
// import utilities from './utilities';
// import support from './support';
// import other from './other';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
    // items: [dashboard, application, forms, elements, pages, utilities, support, other]
    items: [dashboard]
};

export default menuItems;
